import { useCallback, useRef } from 'react';
import { GatewayEvent } from 'shared/hooks/axon/ipcts-gw/gateway-events.enum';
import { demoLog } from 'shared/utils/demo-log';

export interface ISocketFactory {
  wsUrl: string;
  handleIncommingAudio?: (binaryData: Blob) => void;
}

type GwMessage = {
  event: string;
  payload: any;
};

export const useSocketFactory = ({
  wsUrl,
  handleIncommingAudio,
}: ISocketFactory) => {
  const socket = useRef<WebSocket | null>(null);
  const eventHandlers = useRef<Record<string, (value?: any) => any> | null>(
    null
  );
  const setEventsHandlers = (hadlers: Record<string, (value?: any) => any>) => {
    eventHandlers.current = hadlers;
  };
  const handleMsg = (msg: GwMessage) => {
    if (!eventHandlers.current) {
      demoLog('** SocketFactory Error: No Events Loaded in Socket');
      return;
    }
    const handler = eventHandlers.current[msg.event];
    if (!handler) {
      demoLog(`** SocketFactory Error: Handler not found for this event: ${msg.event}`);
      return;
    }
    handler(msg);
  };

  const waitForOpenConnection = () => {
    return new Promise((resolve, reject) => {
      const maxNumberOfAttempts = 10
      const intervalTime = 100
      let currentAttempt = 0
      if(!socket.current){
        resolve(false);
        return;
      }
      if (isOpen()){
        resolve(true);
        return;
      }
      const interval = setInterval(() => {
        if (isOpen()) {
          clearInterval(interval)
          resolve(true)
        } else {
          if (currentAttempt > maxNumberOfAttempts - 1) {
            reject(new Error('waitForOpenConnection Maximum number of attempts exceeded'))
          } else if (socket?.current?.readyState === WebSocket.CLOSING) {
            reject(new Error('waitForOpenConnection socket closing'))
          }
          clearInterval(interval)
        }
        currentAttempt++
      }, intervalTime)
    })
  }
  const send = async (message: string | ArrayBuffer) => {
    if (socket.current) {
      await waitForOpenConnection();
      demoLog('** SocketFactory send: ', message)
      socket.current.send(message)
    }
  };

  const openSocket = async () => {
    const url = new URL(wsUrl);
    await waitForOpenConnection()
    if(isOpen()){
      return;
    }

    socket.current = new WebSocket(url);
    socket.current.onerror = (e) => {
      console.error('** SocketFactory onerror: ', url, e);
      handleMsg({event: GatewayEvent.connectionError, payload: e });
    };
    socket.current.onopen = () => {
      demoLog('** SocketFactory onopen: ', url);
    };
    socket.current.onclose = (e) => {
      demoLog('** SocketFactory onclose: ', e);
      handleMsg({ event: GatewayEvent.connectionClosed, payload: e });
    };
    socket.current.onmessage = (e: MessageEvent) => {
      if (e.data instanceof Blob && handleIncommingAudio) {
        handleIncommingAudio(e.data);
      } else {
        const msg = JSON.parse(e.data);
        handleMsg(msg);
      }
    };

  }

  const closeSocket = () => {
    if (!socket.current) return;
    socket.current!.close();
    socket.current = null;
  };

  const isOpen = () => {
    return socket?.current?.readyState === WebSocket.OPEN;
  }

  return () => ({
    send,
    openSocket,
    closeSocket,
    setEventsHandlers,
    isOpen,
  });
};