import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { useContext } from 'react';
import {
  TranscriptMsg,
  CaptionShard,
  CaptionsSource,
  SimpleShard,
  CaptionShardWord,
} from 'shared/hooks/axon/gateway.types';
import { zeroWidthJoiner } from 'shared/utils/separator-joiners.util';

const checkIfIsFuser = (source: CaptionsSource) => source === 'FUSER';

const formatShardText = (shardText: string) => {
  return shardText
      .replaceAll('<c>', '')
      .replaceAll('</c>', '')
      .replaceAll('&apos;', "'")
}

export const useUpdateTranscript = () => {
  const { captionShards, detailedShards } = useContext(LandingPageContext);

  const updateTranscript = ({ shards, source }: TranscriptMsg) => {
    if (!checkIfIsFuser(source)) {
      return;
    }
    try{
    if (!detailedShards.current) {
        Object.entries(shards as unknown as SimpleShard).forEach(([id, shardText]) => {
          const shard: CaptionShard = {
            shardId: Number(id),
            shardText,
            alternatives: [],
            editable: true,
            shardWords: [],
            formattedText: formatShardText(shardText),
            userEditing: false,
          };
          captionShards!.current[Number(id)] = shard;
        });

        return;
      }

      Object.values(shards).forEach(({ shardId, shardText, shardWords }) => {
        //console.log(`update transcript shardText ${shardText}`)
        if(shardWords.length && !shardWords[0].wordText.includes(zeroWidthJoiner.space)){
          shardWords[0].wordText = zeroWidthJoiner.space + shardWords[0].wordText
        }
        if(shardWords.length === 1 && shardText.includes('<c>')){
          shardWords[0].isCorrection = true;
          shardWords[0].isFirst = true;
          shardWords[0].isLast = true;
        } else {
          let textWords = shardText.split(' ').filter((word: string) => (word.length));
          shardWords.forEach((shardWord: CaptionShardWord, index: number, thisArray: CaptionShardWord[]) => {
            shardWord.shardId = shardId;
            let foundIndex = textWords.findIndex((textWord: string) => (textWord.includes(shardWord.wordText)))
            if(foundIndex !== -1){
              const textWord = textWords[foundIndex];
              if(textWord.includes(`<c>${shardWord.wordText}</c>`)
              || textWord === `${shardWord.wordText}</c>`){
                shardWord.isCorrection = true;
              } else {
                shardWord.isCorrection = false;
              }
              textWords.splice(foundIndex, 1)
            }
            shardWord.isLast = index === thisArray.length-1;
            if(!shardWord.wordText.includes(zeroWidthJoiner.space)){
              shardWord.wordText = zeroWidthJoiner.space + shardWord.wordText
            }
          })
        }

        const captionShard: CaptionShard = {
          shardId: shardId,
          shardText: shardText,
          alternatives: shardWords['0'] ? shardWords[0].wordAlternatives : [],
          editable: true,
          shardWords,
          formattedText: formatShardText(shardText),
          userEditing: false,
        };
        if(!captionShards!.current[Number(shardId)]?.userEditing){
          captionShards!.current[Number(shardId)] = captionShard;
        }
      });

    } catch(error: any) {
      console.error('updateTranscript failed with error ', error.message, '/nstack: ', error.stack)
      console.error('updateTranscript failed with shards ', JSON.stringify(shards))
    }
  };

  return {
    updateTranscript,
  };
};