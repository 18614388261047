import axios, { AxiosError } from 'axios';
import { useFlags } from 'launchdarkly-react-client-sdk';
import { DateTime } from 'luxon';
import {
  AuthenticationContext,
  LANGUAGES_MAP,
} from 'modules/authentication/authentication.context';
import * as React from 'react';
import { openErrorNotification } from 'shared/components/ui/alerts/notification.component';
// import { config } from 'shared/config/cts.config';
import usePostAddAgentQueue from 'shared/datasources/agent-queue-service/use-post-add-agent-queue.hook';
// import usePostRemoveAgentQueue from 'shared/datasources/agent-queue-service/use-post-remove-agent-queue.hook';
import useGetAgent from 'shared/datasources/agent-service/use-get-agent.hook';
import {
  ECallStateCode,
  ESilentCallDisposition,
  ETerminationReasonCode,
} from 'shared/datasources/call-detail-record-service/entities.dto';
// import useGetHostName from 'shared/datasources/config-service/use-get-host';
import {
  CallState,
  EUserStatus,
} from 'shared/datasources/session-state-service/entities.dto';
import { usePostSessionServiceLogin } from 'shared/datasources/session-state-service/use-post-session-service-login.hook';
import { usePostSessionServiceLogout } from 'shared/datasources/session-state-service/use-post-session-service-logout.hook';
import { usePostSessionServiceUpdate } from 'shared/datasources/session-state-service/use-post-session-service-update.hook';
import { CaptionShard } from 'shared/hooks/axon/gateway.types';
import { useNotificationsService } from 'shared/hooks/use-notifications-service.hook';
import { demoLog } from 'shared/utils/demo-log';
import { getActiveDirectoryFromEmail } from 'shared/utils/getActiveDirectoryId';
import { UserStates } from 'shared/hooks/admin/states.enum';
import { useUserState } from 'shared/hooks/admin/use-post-update-user-state.hook';
import { LandingPageContextData } from './landing-page.types';

export const LandingPageContext = React.createContext<LandingPageContextData>({
  currentNotificationId: '',
  readyForCall: false,
  setReadyForCall: () => {},
  dispositionSelected: ETerminationReasonCode.Unknown,
  setDispositionSelected: () => {},
  silentDispositionSelected: ESilentCallDisposition.Unknown,
  setSilentDispositionSelected: () => {},
  setAsrProvider: () => {},
  isCallActive: false,
  setIsCallActive: () => {},
  callId: '',
  setUserToIdle: () => ({}),
  gracefulDisconnectFlow: () => {},
  setCorrectionsCounter: () => {},
  agentId: '',
  registerAgent: () => {},
  unregisterAgent: () => {},
  requestedHandoff: '',
  setRequestedHandoff: () => {},
  asrProvider: '',
  isSessionScreen: false,
  setIsSessionScreen: () => {},
  showQueue: false,
  setShowQueue: () => {},
  handleQueueCancel: () => {},
  sessionServiceLogin: () => Promise.resolve(),
  sessionServiceUpdate: () => Promise.resolve(),
  sessionServiceLogout: () => Promise.resolve(),
  sipUrl: '',
  setCallState: () => {},
  callState: CallState.OneOnCall,
  setCallStateCode: () => {},
  is911Call: false,
  setIs911Call: () => {},
  isHeadphonesDetected: false,
  setIsHeadphonesDetected: () => {},
  setCallId: () => {},
  jssipAudioRef: undefined,
  captionShards: { current: [] },
  detailedShards: { current: true },
  showLobby: false,
  setShowLobby: () => {},
});

const LandingPageProvider = ({ children }: { children: React.ReactNode }) => {
  const { user } = React.useContext(AuthenticationContext);
  const { useAgentQueue } = useFlags();
  const usrId = React.useRef<number>();
  const sessionId = React.useRef<number>();
  const [dispositionSelected, setDispositionSelected] =
    React.useState<ETerminationReasonCode>(ETerminationReasonCode.Unknown);
  const [silentDispositionSelected, setSilentDispositionSelected] =
    React.useState<ESilentCallDisposition>(ESilentCallDisposition.Unknown);
  const [correctionsCounter, setCorrectionsCounter] = React.useState(0);
  const [asrProvider, setAsrProvider] = React.useState('');
  const [sessionStart, setSessionStart] = React.useState('');
  const [sessionEnd, setSessionEnd] = React.useState('');
  const [requestedHandoff, setRequestedHandoff] = React.useState('');
  const [isSessionScreen, setIsSessionScreen] = React.useState(false);
  const [showQueue, setShowQueue] = React.useState(false);
  const { get: getAgent } = useGetAgent();
  const [sipUrl, setSipUrl] = React.useState<string>('');
  const [showLobby, setShowLobby] = React.useState(true);

  const [callState, setCallState] = React.useState<CallState>(
    CallState.OneOnCall
  );
  const [, setCallStateCode] = React.useState<ECallStateCode>(
    ECallStateCode.InProg
  );
  const [is911Call, setIs911Call] = React.useState<boolean>(false);
  const [isHeadphonesDetected, setIsHeadphonesDetected] =
    React.useState<boolean>(true);
  const jssipAudioRef =
    React.useRef() as React.MutableRefObject<HTMLAudioElement>;

  const captionShards = React.useRef<CaptionShard[]>([]);
  const detailedShards = React.useRef<boolean>(true);

  (window as any).is911 = () => {
    if (is911Call) {
      setIs911Call(false);
    } else {
      setIs911Call(true);
    }
  };
  // const { post: postAddAgent } = usePostAddAgent();
  // const { post: deleteRemoveAgent } = useDeleteRemoveAgent();
  // const { post: postModifyAgentState } = usePostModifyAgentState();
  const { post: postAddAgentQueue } = usePostAddAgentQueue();
  // const { post: postRemoveAgentQueue } = usePostRemoveAgentQueue();
  const { post: postSessionServiceLogin } = usePostSessionServiceLogin();
  const { post: postSessionServiceUpdate } = usePostSessionServiceUpdate();
  const { post: postSessionServiceLogout } = usePostSessionServiceLogout();
  // const { post: postGetHostName } = useGetHostName();

  const { updateUserState } = useUserState();
  const {
    currentNotificationId,
    readyForCall,
    setReadyForCall,
    isCallActive,
    setIsCallActive,
    callId,
    setCallId,
  } = useNotificationsService();

  const requestsErrorHandler = async (
    error: Error | AxiosError,
    description: string
  ) => {
    if (axios.isAxiosError(error)) {
      // Access to config, request, and response
      setShowQueue(false);
      openErrorNotification('topRight', description, 'Error');
    } else {
      demoLog('Error in requestErrorHandler()');
    }
  };

  const sessionServiceLogin = async () => {
    await postSessionServiceLogin({
      data: {
        AgentId: Number(usrId.current),
        IsVCA: false,
        CreatedDt: `${DateTime.now().toISO()}`,
        CallCenterId: Number(user?.callCenterId),
        LanguageCode: 'English',
        ApplicationCode: 'CTSWeb',
        ClientSoftwareVersion: '1.0',
      },
    })
      .then((response) => {
        sessionId.current = Number(response.data[1].Session.Id);
      })
      .catch((error) => {
        demoLog(error);
      });
    return Promise.resolve();
  };

  const sessionServiceUpdate = async (statusCode: string) => {
    await postSessionServiceUpdate({
      data: {
        Id: sessionId.current,
        ActivityDt: `${DateTime.now().toISO()}`,
        UsrStatusTypeCode: statusCode,
      },
    }).catch((error) => {
      demoLog(error);
    });
    return Promise.resolve();
  };

  const sessionServiceLogout = async () => {
    await postSessionServiceLogout({
      data: {
        Id: sessionId.current,
        AgentId: Number(usrId.current),
        IsVCA: false,
        ActivityDt: `${DateTime.now().toISO()}`,
      },
    })
      .then(() => {
        sessionId.current = 0;
      })
      .catch((error) => {
        demoLog(error);
      });
    return Promise.resolve();
  };

  const getUsrId = async (username: string) => {
    const response = await getAgent({
      params: {
        username: username.split('@')[0],
      },
    });

    usrId.current = response.data.UsrID;
  };

  const registerAgent = async () => {
    // const data = {
    //   AgentId: user?.username.toLowerCase(),
    //   HostIpAddress: '127.0.0.1',
    //   CallCenterCode: user?.callCenterId,
    //   IsVCA: false,
    //   CreatedDt: '2023-04-11T19:47:05.528Z',
    //   ActiveDirectoryID: getActiveDirectoryFromEmail(user?.username),
    // };
    // await postAddAgent({
    //   data,
    // });
  };

  const unregisterAgent = async () => {
    // await deleteRemoveAgent({
    //   params: {
    //     Id: agent.current?.agentid,
    //   },
    // });
  };

  const handleAgentToIdle = React.useCallback(
    async (notification_id: string) => {
      // const postModifyResponse = await postModifyAgentState({
      //   data: {
      //     AgentId: user?.username.toLowerCase(),
      //     State: 'Idle',
      //     HostIpAddress: agent.current?.hostipaddress,
      //     CallCenterCode: user?.callCenterId,
      //     IsVCA: false,
      //   },
      // }).catch(handleLog('postModifyAgentState'));

      // demoLog('RESPONSE FROM SETTING AGENT STATE TO IDLE', postModifyResponse);
      if (!sipUrl) return;
      await sessionServiceUpdate(EUserStatus.BgnSess);

      await postAddAgentQueue({
        data: {
          // AgentId: user?.username.toLowerCase(),
          AgentId: Number(usrId.current),
          NotificationId: notification_id,
          CreatedDt: `${DateTime.now().toISO()}`,
          HostIpAddress: '127.0.0.1',
          SipAddress: `sip:${user?.username.split('@')[0]}@${sipUrl}`,
          Skills: [
            LANGUAGES_MAP[user?.language! as keyof typeof LANGUAGES_MAP],
          ],
          IsVCA: false,
          ActiveDirectoryID: getActiveDirectoryFromEmail(user?.username),
        },
      }).catch((error: Error | AxiosError) => {
        requestsErrorHandler(
          error,
          'An error occured while adding agent to the queue.'
        );
      });

      await sessionServiceUpdate(EUserStatus.Idle);

      await setReadyForCall(true);
    },
    [sipUrl]
  );

  const handleInitialization = async (notification_id: string) => {
    await handleAgentToIdle(notification_id);
  };

  const handleCallStarted = async () => {
    // demoLog('SENT OBJECT TO /MODIFY-AGENT-STATE', {
    //   ...agent.current,
    //   state: 'Busy',
    // });
    // const postModifyResponse = await postModifyAgentState({
    //   data: {
    //     AgentId: user?.username.toLowerCase(),
    //     State: 'Busy',
    //     HostIpAddress: '127.0.0.1',
    //     CallCenterCode: user?.callCenterId,
    //     IsVCA: false,
    //   },
    // });
    // await demoLog(
    //   'RESPONSE FROM SETTING AGENT STATE TO BUSY',
    //   postModifyResponse
    // );
    // await postRemoveAgentQueue({
    //   params: {
    //     // Id: agent.current?.agentid,
    //     Id: usrId.current,
    //   },
    // });
  };

  const handleCallEnd = React.useCallback(async () => {
    demoLog('ENDING CALL');

    if (currentNotificationId && sipUrl) {
      handleAgentToIdle(currentNotificationId);
    }
  }, [sessionEnd, currentNotificationId, sipUrl, handleAgentToIdle]);

  const handleQueueCancel = async () => {
    updateUserState(UserStates.IN_LOBBY);
    setShowLobby(true)
    setIsCallActive(false)
  };

  const handleCallDisconnect = async () => {
    // await postRemoveAgentQueue({
    //   params: {
    //     // Id: user?.username.toLowerCase(),
    //     Id: usrId.current,
    //   },
    // });
  };

  React.useEffect(() => {
    if (currentNotificationId && showQueue) {
      handleInitialization(currentNotificationId);
    }
  }, [currentNotificationId, showQueue]);

  React.useEffect(() => {
    if (isCallActive) {
      setSessionStart(`${DateTime.now().toISO()}`);
      if (useAgentQueue) {
        handleCallStarted();
      }
    }
    if (!isCallActive && sessionStart !== '') {
      setSessionEnd(`${DateTime.now().toISO()}`);
    }
  }, [isCallActive]);

  React.useEffect(() => {
    demoLog('>>> Session Start: ', sessionStart);
    demoLog('>>> Session End: ', sessionEnd);
    demoLog('>>> TerminationReasonCode: ', dispositionSelected);
    demoLog('>>> Corrections Counter: ', correctionsCounter);

    if (sessionStart !== '' && sessionEnd !== '') {
      handleCallEnd();
      setSessionStart('');
      setSessionEnd('');
      setDispositionSelected(ETerminationReasonCode.Unknown);
    }
  }, [sessionStart, sessionEnd, callId, usrId]);

  React.useEffect(() => {
    setSipUrl('');
    // postGetHostName({
    //   data: {
    //     Config: {
    //       app: 'OpenSips-Application',
    //       profile: 'default',
    //       env: `open-sips-${config.REACT_APP_ENVIRONMENT}-environment`,
    //       region: us-west-2,
    //     },
    //   },
    // }).then((response) => {
    //   setSipUrl(response.data.HostName);
    // });
  }, []);

  return (
    <LandingPageContext.Provider
      value={{
        currentNotificationId,
        readyForCall,
        setReadyForCall,
        isCallActive,
        setIsCallActive,
        callId,
        dispositionSelected,
        setDispositionSelected,
        silentDispositionSelected,
        setSilentDispositionSelected,
        setUserToIdle: handleAgentToIdle,
        gracefulDisconnectFlow: handleCallDisconnect,
        setAsrProvider,
        asrProvider,
        setCorrectionsCounter,
        agentId: usrId.current?.toString()!,
        registerAgent,
        unregisterAgent,
        requestedHandoff,
        setRequestedHandoff,
        isSessionScreen,
        setIsSessionScreen,
        showQueue,
        setShowQueue,
        handleQueueCancel,
        sessionServiceLogin,
        sessionServiceUpdate,
        sessionServiceLogout,
        sipUrl,
        setCallState,
        callState,
        setCallStateCode,
        is911Call,
        setIs911Call,
        isHeadphonesDetected,
        setIsHeadphonesDetected,
        setCallId,
        jssipAudioRef: jssipAudioRef.current,
        captionShards,
        detailedShards,
        showLobby,
        setShowLobby,
      }}
    >
      <audio ref={jssipAudioRef} id="jssip-call" controls hidden />
      {children}
    </LandingPageContext.Provider>
  );
};

export default LandingPageProvider;