import styled from 'styled-components';

export const PopUpContainer = styled.div`
  padding: 12px 16px;
  .input-popup {
    color: gray;
  }
  min-width: 350px;
`;

type HasWordAlternatives = {
  hasWordAlternatives: boolean;
};
export const CorrectionsMenu = styled.div<HasWordAlternatives>`
  background-color: #0F161A;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  overflow: hidden;
  padding: 4px;
  .ant-menu {
    background-color: #0F161A;
  }
  .ant-menu li {
    color:  ${({ hasWordAlternatives }) => hasWordAlternatives
      ? '#95C940'
      : 'rgba(255, 255, 255, 0.55)'};
    background-color: rgba(149, 201, 64, 0.21);
    font-weight: 600;
  }
  .ant-menu-item-active {
    color: #000!important;
    background-color: #95C940!important;
  }    
`;

export const TabContainer = styled.div`
  width: 20px;
  height: 11px;
  font-size: 10px;
  background-color: transparent;
  border: 0.5px solid #466348;
  color: #748f70;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: bold;
`;

type isOnCorrection = {
  isOnCorrection: boolean;
};

export const PShard = styled.p<isOnCorrection>`
  color: ${(props) =>
    props.isOnCorrection
      ? ({ theme }) => theme.colors.red
      : ({ theme }) => theme.colors.fontColor};
`;
