import { notification } from 'antd';
import { HANDOFFS } from 'modules/ipcts-call-session/components/controls/context/call-handoff.types';
import { IPCTSSessionContext } from 'modules/ipcts-call-session/contexts/ipcts-session/ipcts-session.context';
import { IpctsSession } from 'modules/ipcts-call-session/ipcts-session.component';
import PreflightChekScreen from 'modules/ipcts-call-session/pfc-screen/preflight-check.component';
import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import * as React from 'react';
import HandoffScreen from 'shared/components/ui/handoff-screen/handoff-screen.component';
import { QueueScreen } from 'shared/components/ui/queue-screen/queue-screen.component';
import { ECallStateCode } from 'shared/datasources/call-detail-record-service/entities.dto';
import { CallState } from 'shared/datasources/session-state-service/entities.dto';
import { GateWaySockets, TranscriptMsg, CaptionsSource, ShardType } from 'shared/hooks/axon/gateway.types';
import useAudio from 'shared/hooks/use-audio.hook';
import testCaptionShards from 'modules/landing-page/context/test-caption-shards.json'
import { useAllowScreenShare } from 'shared/hooks/use-allow-screen-share';
import { useUpdateTranscript } from 'shared/hooks/axon/ipcts-gw/use-update-transcript.hook';

export const LandingPage = () => {
  const { updateTranscript } = useUpdateTranscript();
  useAllowScreenShare()
  
  const {
    setReadyForCall,
    isCallActive,
    setIsCallActive,
    gracefulDisconnectFlow,
    dispositionSelected,
    setDispositionSelected,
    setSilentDispositionSelected,
    setCorrectionsCounter,
    // registerAgent,
    // unregisterAgent,
    requestedHandoff,
    setRequestedHandoff,
    setShowQueue,
    callState,
    setCallStateCode,
    setIsSessionScreen,
    showLobby,
    setShowLobby,
    captionShards,
    handleQueueCancel,
  } = React.useContext(LandingPageContext);


  const {
    text,
    isActiveSession,
    isTokenReady,
    cardCaptionCallRef,
    handleStopListening,
    handleStartListening,
    reset,
    addCustomCaption,
    correctionsCounter,
    setActiveGateway,
    sendNotAcceptingCalls,
  } = React.useContext(IPCTSSessionContext);

  const { play } = useAudio('/audios/beep.mp3');
  const [isHandoff, setIsHandoff] = React.useState(false);

  const handleCancelQueue = () => {
    sendNotAcceptingCalls('break', 'Out to lunch')
    setShowQueue(false);
    setIsSessionScreen(false);
    handleQueueCancel();    
  }

  React.useEffect(() => {
    window.onbeforeunload = () => '';
    // registerAgent();
    return () => {
      setReadyForCall(false);
      gracefulDisconnectFlow();
      if (!window.electronAPI) {
        window.onbeforeunload = null;
      }
      // unregisterAgent();
    };
  }, []);

  React.useEffect(() => {
    if (isCallActive) {
      // setUserState
      play();
    }
  }, [isCallActive, play]);


  React.useEffect(() => {
    if (correctionsCounter === 0) return;
    setCorrectionsCounter(correctionsCounter);
  }, [correctionsCounter]);

  const dispositionCallEnd = () => {
    reset();
    setIsCallActive(false);
  };

  const handoffCallEnd = () => {
    dispositionCallEnd();
    setIsHandoff(true);
  };

  React.useEffect(() => {
    if (isHandoff) {
      setReadyForCall(false);
      gracefulDisconnectFlow();
    }
  }, [isHandoff]);

  React.useEffect(() => {
    if (callState === CallState.OneOnCallEnd) {
      setCallStateCode(ECallStateCode.InPrgWMi);
      notification.info({
        message: 'One one call end event',
        description: 'The call will end in 15 seconds.',
        placement: 'topRight',
        duration: 10,
      });
    }
  }, [callState]);

  const handleShowLogOutButtonchange = (showLogOutButton: boolean) => {
    if (showLogOutButton) {
      document.querySelector('#logoutButton')?.removeAttribute('hidden');
      return;
    }
    document.querySelector('#logoutButton')?.setAttribute('hidden', 'true');
  };

  //Functions for testing purposes
  (window as any).startcall = () => {
    // play();
    // setIsCallActive(true);
  };

  (window as any).endcall = () => {
    dispositionCallEnd();
  };

  (window as any).addSilenceCaption = () => {
    addCustomCaption('(Silence)');
  };

  (window as any).triggerHandoff = (language: boolean = false) => {
    if (language) {
      setRequestedHandoff(HANDOFFS.ENGLISH_SPANISH.LABEL);
    }
    dispositionCallEnd();
    setIsHandoff(true);
  };

  const showScreen = () => {
    handleShowLogOutButtonchange(false);
    // test code to load test captions for editor development
    // updateTranscript({
    //   "type":"transcriptData",
    //   "source":CaptionsSource.FUSER,
    //   "resultType":"PARTIAL",
    //   "streamId":"original",
    //   "alternatives": [
    //     {
    //       "beginning": 3495,
    //       "confidence": 1,
    //       "ending": 3495,
    //       "normalizedText": " workz.",
    //       "text": " workz.",
    //       "words": [
    //         {
    //           "alternatives": [
    //             {
    //               "beginning": 3495,
    //               "confidence": 1,
    //               "ending": 3495,
    //               "word": "workz."
    //             },
    //             {
    //               "beginning": 2550,
    //               "confidence": 1,
    //               "ending": 3600,
    //               "word": "workz."
    //             },
    //             {
    //               "beginning": 2695,
    //               "confidence": 1,
    //               "ending": 3495,
    //               "word": "workz."
    //             }
    //           ],
    //           "correctionsId": 3600,
    //           "fusionTimestamp": 3495
    //         }
    //       ]
    //     }
    //   ],
    //   shards: testCaptionShards as unknown as ShardType
    // })

    if (showLobby) {
      handleShowLogOutButtonchange(true);

      return (
        <PreflightChekScreen
          setShowQueue={setShowQueue}
          setShowLobby={setShowLobby}
          setIsSessionScreen={setIsSessionScreen}
          text={text}
          handleStartListening={handleStartListening}
          handleStopListening={handleStopListening}
          cardCaptionCallRef={cardCaptionCallRef!}
        />
      );
    }
    if (isCallActive) {
      setActiveGateway(GateWaySockets.captionerSocket)
      return (
        <IpctsSession
          text={captionShards.current}
          isActiveSession={isActiveSession}
          isTokenReady={isTokenReady}
          cardCaptionCallRef={cardCaptionCallRef}
          handleStartListening={handleStartListening}
          setIsCallActive={setIsCallActive}
          dispositionCallEnd={dispositionCallEnd}
          handoffCallEnd={handoffCallEnd}
          addCustomCaption={addCustomCaption}
          dispositionSelected={dispositionSelected}
          setDispositionSelected={setDispositionSelected}
          setSilentDispositionSelected={setSilentDispositionSelected}
        />
      );
    }
    if (!isHandoff) {
      return <QueueScreen onCancelQueue={handleCancelQueue} />;
    }

    if (requestedHandoff === HANDOFFS.ENGLISH_SPANISH.LABEL) {
      return <HandoffScreen isLanguageHandOff />; //goes to queue again
    }

    return <HandoffScreen />;
  };

  return <>{showScreen()}</>;
};