import * as React from 'react';

import RoutesModule from '../routes.module';
import { IRouteModule } from '../routes.types';
import { useFlags, type LDFlagSet } from 'launchdarkly-react-client-sdk';
import { config } from 'shared/config/cts.config';

type RouteModuleContextType = [IRouteModule[]];

const RouteModuleContext = React.createContext(
  [] as any as RouteModuleContextType
);

const HOME_ROUTE = 'Home';
const DOWNLOAD_ROUTE = 'DownloadPage';

export const IS_PROD = config.REACT_APP_ENVIRONMENT === 'prod'

const RouteModuleProvider = ({ children }: { children: React.ReactNode }) => {
  const { useElectronQa } = useFlags<LDFlagSet>();

  const routeModules = React.useMemo<IRouteModule[]>(() => RoutesModule(), []);

  let filteredRoutes: IRouteModule[];
  const PROD_DOWNLOAD_PAGE_CONDITION =
    config.BUILD_TYPE === 'production' && !window?.electronAPI;

  if (IS_PROD) {
    filteredRoutes = PROD_DOWNLOAD_PAGE_CONDITION
      ? routeModules.filter(
          ({ name }) => ![HOME_ROUTE].includes(name)
        )
      : routeModules.filter(({ name }) => name !== DOWNLOAD_ROUTE);
  } else {
    const OTHER_ENV_DOWNLOAD_PAGE_CONDITION =
      PROD_DOWNLOAD_PAGE_CONDITION && !useElectronQa;

    filteredRoutes = OTHER_ENV_DOWNLOAD_PAGE_CONDITION
      ? routeModules.filter(
          ({ name }) => ![HOME_ROUTE].includes(name)
        )
      : routeModules.filter(({ name }) => name !== DOWNLOAD_ROUTE);
  }

  return (
    <RouteModuleContext.Provider value={[filteredRoutes]}>
      {children}
    </RouteModuleContext.Provider>
  );
};

export { RouteModuleContext, RouteModuleProvider };