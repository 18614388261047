import { Button } from 'antd';
import { CardsContainer } from 'shared/components/ui/card-container.styled';

import styled from 'styled-components';

type CheckDisabled = {
  isDisabled?: boolean;
  fontSize: number;
  lineHeight: number;
  fontFamily: string;
};

export const Title = styled.span`
  color: ${({ theme }) => theme.colors.callControlsHeaderText};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  font-size: ${({ theme }) => theme.fontSizes['2xl']};
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const PFCPageContainer = styled.div`
  padding: 0px 24px;
  display: flex;
  flex: auto;
  flex-wrap: wrap;
`;

export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 2 1 auto;
  margin: -35px 17px 0 0;
  flex-basis: min-content;
`;

export const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

export const CardCaptionCall = styled(CardsContainer)<CheckDisabled>`
  height: 87vh;
  padding: ${({ theme }) => theme.fontSizes['2xl']} 21px;
  background-color: ${(props) =>
    props.isDisabled
      ? ({ theme }) => theme.colors.midnightBlue300
      : ({ theme }) => theme.colors.sessionBackground};
  position: relative;
  display: flex;
  flex-direction: row;
  border-radius: 8px;

  .editor-provider-wrapper {
    height: 100%;
  }

  .rsw-editor {
    border: none;
    color: ${({ theme }) => theme.colors.secondaryColorBase};
    height: 100%;
    overflow: inherit;

    .rsw-cts .ant-input {
      font-size: ${({ fontSize }) => fontSize}px;
      line-height: ${({ lineHeight }) => lineHeight}px;
      font-family: ${({ fontFamily }) => fontFamily};
      color: ${({ theme }) => theme.colors.colorText};
      font-weight: 600;
    }

    .rsw-cts {
      font-size: ${({ fontSize }) => fontSize}px;
      line-height: ${({ lineHeight }) => lineHeight}px;
      font-family: ${({ fontFamily }) => fontFamily};
      color: ${({ theme }) => theme.colors.colorText};
      p {
        color: ${({ theme }) => theme.colors.colorText};
        .correction {
          color: ${({ theme }) => theme.colors.greenThemeColor};
        }
      }

      .partial {
        margin-bottom: 0;
        line-height: 1.63;
        display: inline;
      }
      .item {
        margin-bottom: 0;
        line-height: 1.63;
        display: inline;
      }
    }
  }

  .icon-audio-filled,
  .icon-mute-outlined {
    position: sticky;
    font-size: ${({ theme }) => theme.fontSizes['2xl']};
    bottom: 0;
    display: flex;
    align-items: flex-end;
    top: 0;
    color: ${({ theme }) => theme.colors.primaryColorBase};
  }

  & .item.alternative-selection::selection {
    color: ${({ theme }) => theme.colors.alternativesHighlight};
    background: transparent;
    font-family: ${({ theme }) => theme.fonts.inter_semibold};
    font-weight: ${({ theme }) => theme.fontWeights.semiBold};
  }
`;

export const StartButton = styled(Button)`
  padding: 14px 0;
  justify-content: center;
  display: flex;
  background-color: ${({ theme }) => theme.colors.backgroundColor};
  border: none;
  border-radius: 6px;
  height: 72px;
  color: black;
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  align-items: center;
  &:hover,
  :focus {
    border-color: white;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    color: black;
  }
`;

export const StartTestButton = styled(Button)`
  padding: 14px 0;
  position: absolute;
  justify-content: center;
  background-color: ${({ theme }) => theme.colors.testButtonBackground};
  border: none;
  border-radius: 6px;
  height: 56px;
  width: 144px;
  color: ${({ theme }) => theme.colors.fontColor};
  font-family: ${({ theme }) => theme.fonts.inter_medium};
  line-height: 24px;
  font-size: ${({ theme }) => theme.fontSizes.lg};
  align-items: center;
  &:hover,
  :focus {
    border-color: white;
    background-color: ${({ theme }) => theme.colors.backgroundColor};
    color: black;
  }
  z-index: 1;
  top: 45%;
  left: 45%;
`;

export const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;
