import { AuthenticationContext } from 'modules/authentication/authentication.context';
import { config } from 'shared/config/cts.config';
import {
  useContext,
  useEffect,
  useState,
  FC,
  PropsWithChildren,
  createContext,
} from 'react';

type QuickWord = {
  id: number;
  abbreviation: string;
  description: string;
};

type QuickWordObject = {
  [key: string]: string;
};

type QuickWordContextType = {
  Fkeys: QuickWordObject;
  corrections: QuickWordObject;
};
const QuickWordContext = createContext<QuickWordContextType | null>(null);
const FUNCTION_KEY_REGEX = /F\d+/;

const quickWordConverter = (quickwords: QuickWord[]) =>
  quickwords.reduce((acc: QuickWordObject, quickword: QuickWord) => {
    const { abbreviation, description } = quickword;
    acc[abbreviation] = description;
    return acc;
  }, {});

const QuickWordsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { getUserToken } = useContext(AuthenticationContext);
  const [corrections, setCorrections] = useState<QuickWordObject>({});
  const [Fkeys, setFkeys] = useState<QuickWordObject>({});

  useEffect(() => {
    const fetchQuickWords = async () => {
      try {
        const headers: HeadersInit = new Headers();
        headers.set('Authorization', `Bearer ${getUserToken()}`);
        const data = await fetch(
          `${config.REACT_APP_CAPTIONER_SERVICES_API}/quickwords`,
          {
            method: 'GET',
            headers,
          }
        );

        const parsedData = await data.json();

        setCorrections(
          quickWordConverter(
            parsedData?.data?.filter(
              (quickword: QuickWord) =>
                !quickword.abbreviation.match(FUNCTION_KEY_REGEX)
            )
          )
        );
        setFkeys(
          quickWordConverter(
            parsedData?.data
              ?.filter((quickword: QuickWord) =>
                quickword?.abbreviation.match(FUNCTION_KEY_REGEX)
              )
              .map((quickword: QuickWord) => ({
                ...quickword,
                abbreviation: quickword.abbreviation.split('_')[0],
              }))
          )
        );
      } catch (error) {
        console.log(error);
      }
    };

    fetchQuickWords();
  }, []);

  return (
    <QuickWordContext.Provider value={{ Fkeys, corrections }}>
      {children}
    </QuickWordContext.Provider>
  );
};

const useQuickwordContext = () => {
  const quickWordContext = useContext<QuickWordContextType | null>(
    QuickWordContext
  );

  if (!quickWordContext) {
    throw new Error(
      'useCurrentUser has to be used within <QuickWordsContext.Provider>'
    );
  }

  return quickWordContext;
};

export { QuickWordsContextProvider, useQuickwordContext };
