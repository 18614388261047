/* eslint-disable react/prop-types */

import {
  ForwardedRef,
  forwardRef,
  HTMLAttributes,
  memo,
  SyntheticEvent,
} from 'react';
import { CaptionShard } from 'shared/hooks/axon/gateway.types';
import { ShardParagraph } from 'shared/components/ui/editor/editor/shardParagraph';

function blurContentEditable(event: any){
  event.preventDefault();
  event.stopPropagation();
}
// Based on example https://github.com/lovasoa/react-contenteditable
export const ContentEditable = memo(
    function ContentEditable(
    {
      className,
      disabled,
      tagName,
      value,
      editorRef,
      ...rest
    }: ContentEditableProps,
  ) {
    const {onFocus, ...editorProps} = rest;
    return (
      <div 
        contentEditable={true}
        suppressContentEditableWarning={true}
        onBlur={blurContentEditable}
        {...editorProps}
        className={className} 
      >
        {value?.map((item, index) => (
          <ShardParagraph
            key={`shard-${item.shardId}`}
            {...item}
          />
        ))}
      </div>
    );
  }
)

export type ContentEditableEvent = SyntheticEvent<any, Event> & {
  target: { name?: string; value: string };
};

export interface ContentEditableProps extends HTMLAttributes<HTMLElement> {
  disabled?: boolean;
  name?: string;
  onChange?: (event: ContentEditableEvent) => void;
  onClick:  (event: any) => void;
  tagName?: string;
  value?: CaptionShard[];
  editorRef?: any;
  isCTS?: boolean;
}
