import { useFlags } from 'launchdarkly-react-client-sdk';
import { IS_PROD } from 'modules/routing/context/route-model.context';
import { useEffect } from 'react';

export const useAllowScreenShare = () => {
  const { setContentProtection } = useFlags();
  useEffect(() => {
    if (IS_PROD) {
      return;
    } else {
      console.log(
        `** Setting the value of setContentProtection to: ${setContentProtection}`
      );

      window?.electronAPI?.toggleContentProtection(setContentProtection);
    }
  }, [setContentProtection]);
};
