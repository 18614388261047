import * as React from 'react';
import { useEffect } from 'react';
import { ContentEditable, ContentEditableProps } from './ContentEditable';
import { useEditorState } from './EditorContext';
import { useEditorEvents } from './useEditorEvents'
import { CaptionShard } from 'shared/hooks/axon/gateway.types';
import '../styles.css';

export function Editor({ value }: EditorProps) {
  const containerRef = React.useRef<any>();

  const {
    handleBlur,
    handleKeyDown,
    handleKeyup,
    handleClick,
    handleMouseUp,
  } = useEditorEvents()

  useEffect(() => {
    document.addEventListener('click', onClickOutside);
    return () => document.removeEventListener('click', onClickOutside);
  });

  function onClickOutside(event: MouseEvent) {
    if (event.target === containerRef.current) {
      return;
    }

    if (containerRef.current!.contains(event.target as HTMLElement)) {
      return;
    }
    if(handleBlur){
      handleBlur();
    }

  }
  return (
    <div ref={containerRef} className="rsw-editor" tabIndex={0} >
      <ContentEditable 
        id={'rsw-cts-editor'} 
        className="rsw-cts" 
        value={value}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyup}
        onClick={handleClick}
        onMouseUp={handleMouseUp}
      />
    </div>
  );
}

export interface EditorProps {
  value?: CaptionShard[];
}
