import { RouteModuleContext } from 'modules/routing/context/route-model.context';
import { linkRoutes } from 'modules/routing/helpers/linkRoutes';
import { IFeatureFlag, ILinkedRoute } from 'modules/routing/routes.types';
import * as React from 'react';

export const useRoutes = (featureFlags: IFeatureFlag[]): [ILinkedRoute[]] => {
  const [routeModules] = React.useContext(RouteModuleContext);
  const routes = React.useMemo(
    () => linkRoutes(routeModules, featureFlags),
    [routeModules, featureFlags]
  );

  return [routes];
};