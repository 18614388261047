import LogoutSVG from 'assets/icons/logout-button-icon.svg';
import { AuthenticationContext } from 'modules/authentication/authentication.context';
import * as React from 'react';
import * as Styled from './header.styled';
import { UserStates } from 'shared/hooks/admin/states.enum';
import { useUserState } from 'shared/hooks/admin/use-post-update-user-state.hook';

type DesktopHeaderProps = {
  isDesktop: boolean;
};
const KEY_FROM_ELECTRON_API = 'logoutAttempt';

const DesktopHeader: React.FC<DesktopHeaderProps> = ({ isDesktop }) => {
  const { user, logout } = React.useContext(AuthenticationContext);
  const { updateUserState } = useUserState();

  const handleLogout = async () => {
    if (window?.electronAPI && KEY_FROM_ELECTRON_API in window?.electronAPI) {
      await updateUserState(UserStates.OFFLINE);
      window?.electronAPI?.logoutAttempt(false);
    } else {
      logout();
    }
  };

  return (
    <>
      {isDesktop ? (
        <Styled.DesktopHeaderContainer>
          <Styled.RightContainer>
            <Styled.LogoutButton id="logoutButton" onClick={handleLogout}>
              <Styled.LogoutSVG src={LogoutSVG} />
              Log out
            </Styled.LogoutButton>
            <Styled.UserInfoContainer>
              <Styled.Name>{user?.name}</Styled.Name>
              <Styled.Username id="userText">{user?.username}</Styled.Username>
            </Styled.UserInfoContainer>
          </Styled.RightContainer>
        </Styled.DesktopHeaderContainer>
      ) : (
        <Styled.MobileHeaderContainer>
          <Styled.RightMobileContainer>
            <Styled.Name>{user?.name}</Styled.Name>
          </Styled.RightMobileContainer>
        </Styled.MobileHeaderContainer>
      )}
    </>
  );
};

export default DesktopHeader;