import * as React from 'react';

import * as Styled from './expiration-modal.component.styled';

type ExpirationModalProps = {
  onCancel: () => void;
  onSubmit: () => void;
  isModalOpen: boolean;
};

const ExpirationModal: React.FunctionComponent<ExpirationModalProps> = ({
  onCancel,
  onSubmit,
  isModalOpen,
}) => (
  <Styled.Modal
    title="Auth"
    open={isModalOpen}
    onOk={onSubmit}
    onCancel={onCancel}
    okText="Continue"
    cancelText="Log out"
    centered
  >
    You&apos;re about to be signed out due to inactivity. Press continue to stay
    signed in.
  </Styled.Modal>
);

export default ExpirationModal;
