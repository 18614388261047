import { LandingPageContext } from 'modules/landing-page/context/landing-page.context';
import { useContext } from 'react';
import {
  AffectedShards,
  ShardCorrection,
} from 'shared/hooks/axon/corrections/corrections.types';
import { PFCEvents } from 'shared/hooks/axon/ipcts-pfc-gw/pfc-events.enum';



function buildGatewayCorrectionEnvalope(shardCorrectionMsg: any){
  return {
    event: PFCEvents.correction,
    payload: shardCorrectionMsg,
  };
}
export const useCorrections = (send: (data: string) => void) => {
  const { captionShards } = useContext(LandingPageContext);
  
  function affectedShardsHaveDifference(affectedShards: AffectedShards[]){
    let difference = false;
    for(let shard of affectedShards){
      if(shard.old_text != shard.new_text
      && shard.old_text != ' ' + shard.new_text
      ){
        difference = true;
        break;
      }
    }
    return difference;
  }
  // The shard correction message can be sent to fully replace the content of one or more shards
  const sendCorrection = (affectedShards: AffectedShards[]) => {
    if (!captionShards?.current) {
      return;
    }
    if (!affectedShardsHaveDifference(affectedShards)) {
      return;
    }

    const ShardCorrectionMsg: ShardCorrection = {
      type: 'correction',
      correction_type: 'SHARD_CORRECTION',
      correction: {
        affected_shards: affectedShards,
      },
    };

    const msgToGateway = {
      event: PFCEvents.correction,
      payload: ShardCorrectionMsg,
    };

    send(JSON.stringify(msgToGateway));
  };

  const sendInsertion = (priorWordId: number, priorWord: string, newText: string) => {
    const ShardCorrectionMsg = {
      type: 'correction',
      correction_type: 'INSERTION',
      correction: {
        prior_word_id: priorWordId,
        prior_word: priorWord,
        post_word_id: -1,
        post_word: '',
        new_text: newText
      }
    }
    const gatewayMessage = buildGatewayCorrectionEnvalope(ShardCorrectionMsg)
    send(JSON.stringify(gatewayMessage));
  }

  const sendDeletion = (wordId: number, deletedWord: string) => {

    const deletion = {
      type: 'correction',
      correction_type: 'DELETION',
      correction: {
        word_id: wordId,
        word: deletedWord,
      }
    };
    const gatewayMessage = buildGatewayCorrectionEnvalope(deletion)
    send(JSON.stringify(gatewayMessage));
  };

  const sendSubstitution = (wordId: number, replaceText: string, newText: string) => {
    const substitution = {
      type: 'correction',
      correction_type: 'SUBSTITUTION',
      correction: {
        word_id: wordId,
        word: replaceText,
        new_text: newText
      },
    };
    const gatewayMessage = buildGatewayCorrectionEnvalope(substitution)
    send(JSON.stringify(gatewayMessage));
  };

  const finishCorrections = () => {
    const msgToGateway = {
      event: PFCEvents.correctionsFinished,
      payload: {},
    };
    send(JSON.stringify(msgToGateway));
  };

  return {
    sendCorrection,
    sendDeletion,
    finishCorrections,
    sendInsertion,
    sendSubstitution,
  };
};